/* @media only screen and (max-width: 768px) and (min-width: 0px) {
    .banner {
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3%;
    }

    .bannerText {
      font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 768px) {
    .banner {
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1%;
    }

    .bannerText {
      font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    .banner {
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1%;
    }

    .bannerText {
      font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .banner {
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1%;
    }

    .bannerText {
      font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      text-decoration: underline;
    }
  } */

.banner {
  font-family: RlBasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  background-color: #fbf9f6;
  color: #121214;
  font-size: 12px;
  display: flex;
  justify-content: center;
  padding: 10px;
  font-weight: 500;
}
.bannerText1 {
}
.bannerText2 {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .banner {
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    background-color: #fbf9f6;
    color: #121214;
    font-size: 16px;
    display: flex;
    justify-content: center;
    padding: 15px;
    font-weight: 400;
  }
  .bannerText1 {
  }
  .bannerText2 {
    text-decoration: underline;
  }
}
