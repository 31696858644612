.heading2 {
  font-family: "Times New Roman", Times, serif;
  font-weight: 900;
  font-size: 32px;
}

.headingContainer2 {
  height: 130px;
  width: 75%;
  text-align: center;
  margin: 20px 10px;
}

.text2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
}

.headingBackground2 {
  background-color: #f2e9ff;
  position: relative;
  z-index: -1;
  margin: 0 auto;
  height: 20%;
  bottom: -30%;
  transform: translateY(30%);
  min-width: 42%;
  max-width: 42%;
}

.subHeading2 {
  font-size: 16px;
  font-weight: 400;
  font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
  margin: 20px 0px;
  line-height: 1.5;
}

.headBtn1 {
  background-color: #593cfb;
  color: white;
  line-height: 50px;
  font-weight: bold;
  border: 1px solid white;
  font-size: 18px;
  padding: 0px 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .heading2 {
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    font-size: 32px;
  }

  .headingContainer2 {
    height: 130px;
    width: 75%;
    text-align: center;
  }

  .text2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
  }

  .headingBackground2 {
    background-color: #f2e9ff;
    position: relative;
    z-index: -1;
    margin: 0 auto;
    height: 20%;
    width: 90%;
    bottom: 70%;
    transform: translateY(-70%);
  }

  .subHeading2 {
    font-size: 16px;
    font-weight: 400;
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    margin: 20px 0px;
    line-height: 1.5;
  }

  .headBtn1 {
    background-color: #593cfb;
    color: white;
    line-height: 50px;
    font-weight: bold;
    border: 1px solid white;
    font-size: 18px;
    padding: 0px 10px;
  }
}

@media only screen and (min-width: 768px) {
  .heading2 {
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    font-size: 51px;
  }
  .headingBackground2 {
    background-color: #f2e9ff;
    position: relative;
    z-index: -1;
    margin: 0 auto;
    height: 20%;
    bottom: 100px;
    width: 400px;
    min-width: 400px;
  }
}