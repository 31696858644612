.mobileFooter {
  background-color: #f4f4f4;
  font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
}
.mobileFooterSection1 {
  width: 300px;
  padding: 10px;
}
.mobileFooterSection1a {
  display: flex;
  align-items: center;
}
.mobileFooterSection1aText {
  border: 1px solid black;
  width: 40px;
  padding: 2px 4px;
  font-size: 11px;
  border-radius: 3px;
  color: #231f20;
}
.iconSpacing {
  margin: 0 8px;
}
.icon2Spacing {
  margin: 0 5px;
}
.icon3Spacing {
  margin: 0 3px;
}

.mobileFooterSection1b {
  padding: 2px;
  margin-top: 15px;
}

.mobileFooterSection1c {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.mobileFooterSection1cText1 {
  font-size: 12px;
  font-weight: bold;
}

.mobileFooterSection3 {
  font-weight: 500;
  font-size: 12px;
  color: #121214;
  padding: 15px;
}
.mobileFooterSection3a {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mobileFooterSection3aText{
  margin: 10px;
}
