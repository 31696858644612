.carouselContainer5 {
  width: 90%;
  margin: 0 auto;
}
@media only screen and (min-width: 420px) {
  .carouselContainer5 {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 768px) {
  .carouselContainer5 {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  .carouselContainer5 {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1200px) {
  .carouselContainer5 {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1500px) {
  .carouselContainer5 {
    width: 90%;
    margin: 0 auto;
  }
}
