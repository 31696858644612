
.carouselContainer2 {
  width: 90%;
  margin: 50px auto;
}
.carouselHeading {
  font-size: 21px;
  font-weight: bold;
}

.carouselHeadingContainer {
  margin-left: 75px;
}



@media only screen and  (max-width: 768px) {

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
}

@media only screen and (min-width: 992px)  {
  .carouselContainer2 {
    width: 90%;
    margin: 100px auto;
  }
}

@media only screen and (min-width: 1201px) {
}
