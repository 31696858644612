.card5Container {
}
.card5MainContainer {
  width: 80vw;
  margin-left: 20px;
  margin-bottom: 20px;
  border: 2px solid white;
  box-shadow: 0 2px 4px 0 rgb(35 31 32 / 20%);
  border-radius: 8px;
  font-family: BasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
}
.card5Section1 {
  display: flex;
  align-items: center;
  padding: 20px;
}
.card5Section1a {
}
.card5Section1aImg {
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 40px;
  margin-right: 22px;
}
.card5Section1b {
}
.card5Section1bText1 {
  font-size: 16px;
  font-weight: 900;
}
.card5Section1bRatingContainer {
  display: flex;
  margin-top: 10px;
}
.card5Section1bImg {
}
.card5Section1bText2 {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.card5Section1bText3 {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
}
.card5Section2 {
  display: flex;
  padding: 0 20px;
}
.card5Section3 {
  padding: 0 20px;
  margin-top: 10px;
}
.card5Section3Text1 {
  font-size: 16px;
  font-weight: 600;
  height: 40px;
}
.card5Section4 {
  padding: 0 20px;
  margin-top: 40px;
}
.card5Section4Text1 {
  font-size: 12px;
  font-weight: 500;
}
@media only screen and (min-width: 420px) {
  .card5Container {
  }
  .card5MainContainer {
    height: 265px;
    width: 350px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 2px solid white;
    box-shadow: 0 2px 4px 0 rgb(35 31 32 / 20%);
    border-radius: 8px;
    font-family: BasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  }
}
@media only screen and (min-width: 768px) {
  .card5Container {
  }
  .card5MainContainer {
    height: 265px;
    width: 350px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 2px solid white;
    box-shadow: 0 2px 4px 0 rgb(35 31 32 / 20%);
    border-radius: 8px;
    font-family: BasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  }
}
@media only screen and (min-width: 992px) {
  .card5Container {
  }
  .card5MainContainer {
    height: 265px;
    width: 350px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 2px solid white;
    box-shadow: 0 2px 4px 0 rgb(35 31 32 / 20%);
    border-radius: 8px;
    font-family: BasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  }
}
@media only screen and (min-width: 1200px) {
  .card5Container {
  }
  .card5MainContainer {
    height: 265px;
    width: 350px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 2px solid white;
    box-shadow: 0 2px 4px 0 rgb(35 31 32 / 20%);
    border-radius: 8px;
    font-family: BasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  }
}
@media only screen and (min-width: 1500px) {
  .card5Container {
  }
  .card5MainContainer {
    height: 265px;
    width: 350px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 2px solid white;
    box-shadow: 0 2px 4px 0 rgb(35 31 32 / 20%);
    border-radius: 8px;
    font-family: BasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  }
}
