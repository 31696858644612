.heading1 {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 90px;
  margin: 0 0 -10px 0;
  padding: 0;
}

.subHeading4 {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 51px;
  margin: 0;
  padding: 0;
}

.subHeading4Container1 {
  height: 130px;
  width: 50%;
  text-align: center;
}
.text4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
}

.subHeading4Background1 {
  height: 30px;
  background-color: #f2e9ff;
  position: relative;
  top: 62px;
  z-index: -1;
  width: 60%;
  left: 128px;
}


@media only screen and (max-width: 1200px) and (min-width: 992px){
  .heading1 {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 90px;
    margin: 0 0 -10px 0;
    padding: 0;
  }
  
  .subHeading4 {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 51px;
    margin: 0;
    padding: 0;
  }
  
  .subHeading4Container1 {
    height: 130px;
    width: 60%;
    text-align: center;
  }
  .text4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
  }
  
  .subHeading4Background1 {
    height: 30px;
    background-color: #f2e9ff;
    position: relative;
    top: 62px;
    z-index: -1;
    width: 60%;
    left: 128px;
  }
  
   
}