.carouselHeading1 {
  font-family: Arial, RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica,
    sans-serif;
  font-size: 21px;
  font-weight: bold;
}
.slider1MainContainer {
  width: 90%;
  margin: 10px auto;
}
.carousel1BtnContainer {
  display: flex;
}
.carouselHeading1Container {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  margin-top: 30px;
}
.carousel1BtnStyling {
  margin: 0 10px;
}
.carousel1BtnStyling:hover {
  cursor: pointer;
}
@media only screen and (max-width: 425px) {
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1201px) {
}
