.accord2Header {
    font-weight: bold;
}
.accord2Text {
    font-weight: 500;
    color: #121214;
    font-size: 16px;
    padding: 5px 25px;

}
