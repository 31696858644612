.slider1Card1Container {
  font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 900;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  border-radius: 5px;
  width: 95%;
}
.slider1Card1Container:hover{
  cursor: pointer;
  .slider1Card1Title{
    color: #6930f4;
  }
}

.slider1Card1Img {
  width: 100%;
}
.slider1Card1Title {
  margin-top: 10px;
}

@media only screen and (max-width: 425px) {
  .slider1Card1Container {
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    width: 95%;
    height: 80vw;
  }
  .slider1Card1Img {
    width: 100%;
  }
  .slider1Card1Title {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .slider1Card1Container {
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    width: 95%;
    height: 42vw;
  }

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .slider1Card1Container {
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    width: 95%;
    height: 28vw;
  }

}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .slider1Card1Container {
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    width: 95%;
    height: 21vw;
  }
}

@media only screen and (min-width: 1201px) {
  .slider1Card1Container {
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    width: 95%;
    height: 17vw;
  }
}
