.headingContainer {
  position: relative;
  margin: 10px auto;
  font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headingText1 {
  font-size: 38px;
  font-weight: 900;
}
.headingText2 {
  font-size: 16px;
  font-weight: 900;
  margin-top: 10px;
  font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
  width: 85%;
  margin: 0 auto;
}
.headingBackground {
  position: absolute;
  background-color: #f3e8fe;
  height: 20%;
  width: 90%;
  z-index: -1;
  bottom: 0px;
  max-width: 750px;
}
@media only screen and (min-width: 768px) {
  .headingContainer {
    position: relative;
    margin: 10px auto;
    font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .headingText1 {
    font-size: 68px;
    font-weight: 900;
  }
  .headingText2 {
    font-size: 28px;
    font-weight: 900;
    margin-top: 10px;
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    width: 85%;
    margin: 0 auto;
  }
  .headingBackground {
    position: absolute;
    background-color: #f3e8fe;
    height: 20%;
    width: 90%;
    z-index: -1;
    bottom: -5px;
  }
}
@media only screen and (min-width: 992px) {
  .headingContainer {
    position: relative;
    margin: 10px auto;
    font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .headingText1 {
    font-size: 90px;
    font-weight: 900;
  }
  .headingText2 {
    font-size: 28px;
    font-weight: 900;
    margin-top: 10px;
    font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    width: 85%;
    margin: 0 auto;
  }
  .headingBackground {
    position: absolute;
    background-color: #f3e8fe;
    height: 20%;
    width: 90%;
    z-index: -1;
    bottom: -10px;
  }}
