.text3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
}
.headingContainer3 {
  height: 130px;
  width: 75%;
  text-align: center;
  margin: 20px 10px;
}
.heading3 {
  font-family: "Times New Roman", Times, serif;
  font-weight: 900;
  font-size: 32px;
}
.headingBackground3 {
  background-color: #f2e9ff;
  position: relative;
  z-index: -1;
  margin: 0 auto;
  height: 20%;
  bottom: -30%;
  transform: translateY(30%);
  min-width: 42%;
  max-width: 42%;
}

@media only screen and (max-width: 768px) {
  .heading3 {
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    font-size: 32px;
  }

  .headingContainer3 {
    height: 130px;
    width: 75%;
    text-align: center;
  }

  .text3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    font-family: RlFreight, HoeflerText-Black, "Times New Roman", serif;
  }

  .headingBackground3 {
    background-color: #f2e9ff;
    position: relative;
    z-index: -1;
    margin: 0 auto;
    height: 20%;
    bottom: 10%;
    transform: translateY(-10%);
  }
}

@media only screen and (min-width: 768px) {
  .heading3 {
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    font-size: 51px;
  }
  .headingBackground3 {
    background-color: #f2e9ff;
    position: relative;
    z-index: -1;
    margin: 0 auto;
    height: 20%;
    bottom: 28px;
    width: 400px;
    min-width: 400px;
  }
}
