.img2Container {
  font-family: RlBasisGrotesque, Avenir, "Helvetica Neue", Helvetica, sans-serif;
  width: 100%;
  margin-top: 180px;
  position: relative;
}

.img2Styling {
  background-size: cover;
  width: 100%;
}

.img2TextContainer {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  width: 70%;
  position: absolute;
  bottom: -110px;
  left: 95px;
  padding: 25px;
}

.img2Text1 {
  font-size: 12px;
  font-weight: 900;
}

.img2Text2 {
  font-size: 21px;
  font-weight: 900;
  margin-top: 10px;
}

/* .img2Text3 {
  font-size: 17px;
} */

.img2Text4 {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .img2Container {
    font-family: RlBasisGrotesque, Avenir, "Helvetica Neue", Helvetica,
      sans-serif;
    width: 70%;
    margin-top: 180px;
    position: relative;
    margin: 120px 26vw;
  }

  .img2Styling {
    background-size: cover;
    width: 100%;
  }

  .img2TextContainer {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    width: 70%;
    position: absolute;
    bottom: 70px;
    left: -90px;
    padding: 25px;
    min-width: 300px;
    max-width: 300px;
  }

  .img2Text1 {
    font-size: 12px;
    font-weight: 900;
  }

  .img2Text2 {
    font-size: 21px;
    font-weight: 900;
    margin-top: 10px;
  }

  /* .img2Text3 {
    font-size: 17px;
  } */

  .img2Text4 {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .img2Container {
    font-family: RlBasisGrotesque, Avenir, "Helvetica Neue", Helvetica,
      sans-serif;
    width: 60%;
    margin-top: 180px;
    position: relative;
    margin: 120px 26vw;
  }

  .img2Styling {
    background-size: cover;
    width: 100%;
  }

  .img2TextContainer {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    width: 70%;
    position: absolute;
    bottom: 10vw;
    left: -90px;
    padding: 25px;
    min-width: 300px;
    max-width: 300px;
  }

  .img2Text1 {
    font-size: 12px;
    font-weight: 900;
  }

  .img2Text2 {
    font-size: 21px;
    font-weight: 900;
    margin-top: 10px;
  }

  /* .img2Text3 {
    font-size: 17px;
  } */

  .img2Text4 {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 10px;
  }
}
