.card3MainContainer {
  font-family: RlBasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 900;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 5px;
  border-radius: 10px;
  width: 92%;
}
.card3MainContainer:hover{
  cursor: pointer;
}

.cardImgContainer {
  background-size: cover;
  border: 0px solid white;
  border-radius: 10px 10px 0 0;
  width: 76%;
  margin-top: 20px;
}

.cardCardText {
  margin: 10px;
}

@media only screen and (max-width: 425px) {
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1201px) {
}
